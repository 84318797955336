import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map, List } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useContext, useMemo } from "react";
import { Link } from "react-router-dom";

import Dotdotdot from "components/Common/Dotdotdot";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import EntityImage from "components/Entities/EntityImage";

import ReviewCardContext from "./ReviewCardContext";

import generateTransition from "utils/generateTransition";
import { truncateString } from "utils/truncate";

import useEntity from "hooks/useEntity";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  reviewRepliesStub: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 0 0 16px",
    fontSize: "0.8125rem",
    cursor: "pointer",

    [ScreenSizes.mdAndAbove]: {
      ":hover .reviewDeleteButton": {
        opacity: 0.25,
      },
      ":hover .reviewDeleteButton:hover": {
        opacity: 1,
      },
    },
  },
  stubImage: {
    display: "block",
    width: 24,
    height: 24,
    marginRight: "0.75rem",
    flex: "0 0 auto",
    alignSelf: "flex-start",
  },
  content: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flex: "0 1 auto",
    overflow: "hidden",
    lineHeight: 1.6,
  },
  displayName: {
    ...gStyles.textEllipsis,
    ...gStyles.fontBold,
    display: "block",
    flex: "1 0 auto",
    maxWidth: "100%",
  },
  displayNameWithContent: {
    paddingRight: "0.5em",
    flex: "0 0 auto",
    maxWidth: "100%",
  },
  date: {},
  andText: {},
  contentText: {
    display: "block",
    flex: "1 1 auto",
  },
  deleteButton: {
    color: colours.oldSecondary,
    alignSelf: "flex-start",
    justifySelf: "flex-end",
    marginLeft: "auto",
    marginRight: "0.75rem",
    paddingLeft: "0.25rem",
    opacity: 0.25,
    marginTop: "0.1em",

    transition: generateTransition({
      target: "opacity",
      speed: "150ms",
    }),
    [ScreenSizes.mdAndAbove]: {
      marginRight: "1.3rem",
      opacity: 0, // See reviewRepliesStub above
    },
  },
};

const ReviewStub = (props) => {
  const { replies, reply, onToggle, link } = props;
  const { styles } = useStyles(baseStyles, props);
  const { onDeleteReply, onDeleteReview, isReviewOwner } =
    useContext(ReviewCardContext);
  const firstReply = reply || (replies && replies.get(0));

  const { entity: owner } = useEntity(
    firstReply &&
      (firstReply.get("owner_type") || firstReply.get("reply_as_entity_type")),
    firstReply &&
      (firstReply.get("owner_id") || firstReply.get("reply_as_entity_id"))
  );

  const isOwner = useMemo(
    () => isReviewOwner(firstReply, owner),
    [firstReply, isReviewOwner, owner]
  );

  const handleDelete = useCallback(() => {
    if (firstReply) {
      onDeleteReply(firstReply);
    } else {
      onDeleteReview();
    }
  }, [onDeleteReview, onDeleteReply, firstReply]);

  const renderDeleteTooltip = useCallback(() => "Delete Reply", []);

  if (!owner) {
    return null;
  }

  const ownerName =
    owner.get("title") ||
    owner.get("name") ||
    owner.get("display_name") ||
    owner.get("informal_name");

  const andText =
    replies.size > 1 &&
    `and ${replies.size - 1} other${replies.size > 2 && "s"} responded.`;

  // TODO: Use CardActions or a better way to make these function more consistently
  const renderDeleteBadge = () => (
    // TODO: Share with RateReviewBubbleWithoutReplies?
    <BasicTooltip renderTooltip={renderDeleteTooltip} hideOnTouchDevice>
      {(tooltipProps) => (
        <div
          className={`reviewDeleteButton ${css(styles.deleteButton)}`}
          {...tooltipProps}
        >
          <FontAwesomeIcon icon={faTrash} onClick={handleDelete} />
        </div>
      )}
    </BasicTooltip>
  );

  const StubComponent = link ? Link : "div";
  const stubProps = link ? { to: link } : { onClick: onToggle };

  return (
    <Fragment>
      <StubComponent className={css(styles.reviewRepliesStub)} {...stubProps}>
        <span className={css(styles.stubImage)}>
          <EntityImage
            entity_type={
              firstReply.get("owner_type") ||
              firstReply.get("reply_as_entity_type")
            }
            entity={owner}
            cacheSize={128}
            size={128}
            disableLink
            fullWidth
          />
        </span>
        <span className={css(styles.content)}>
          <span
            className={css(
              styles.displayName,
              !andText && styles.displayNameWithContent
            )}
          >
            {ownerName}
          </span>
          {andText || (
            <Dotdotdot
              clamp={2}
              tagName="span"
              className={css((andText && styles.andText) || styles.contentText)}
            >
              {truncateString(firstReply.get("content"), 200)}
            </Dotdotdot>
          )}
        </span>
        {isOwner && renderDeleteBadge()}
      </StubComponent>
    </Fragment>
  );
};

ReviewStub.propTypes = {
  replies: PropTypes.instanceOf(List),
  reply: PropTypes.instanceOf(Map),
  onToggle: PropTypes.func,
  link: PropTypes.string,
};

ReviewStub.defaultProps = {
  replies: null,
  reply: null,
  onToggle: null,
  link: null,
};

export default memo(ReviewStub);
