import { faReply } from "@fortawesome/free-solid-svg-icons/faReply";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTrash } from "@fortawesome/pro-solid-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useContext, useMemo } from "react";

import ShareButton from "components/Buttons/Items/ShareButtonAsync";
import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import RatingWithHover from "components/Rating/RatingWithHoverAsync";

import CardActions from "../../CardActions";
import Bubble from "./Bubble";
import BubbleActions from "./BubbleActions";
import BubbleHeader from "./BubbleHeader";
import ReviewCardContext from "./ReviewCardContext";
import ReviewPrompt from "./ReviewPrompt";
import ReviewReplies from "./ReviewReplies";
import ReviewReplyInput from "./ReviewReplyInput";

import modalActions from "actions/modals";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import generateTransition from "utils/generateTransition";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import cardStyles from "styles/CardStyles";
import colours from "styles/colours";
import { HOVER_QUERY } from "styles/getHoverQuery";

const baseStyles = {
  actionButton: {
    backgroundColor: colours.white,
    color: colours.oldSecondary,
    borderRadius: "12.5px",
    boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.08)",
    padding: "4px 7px 3px",
    marginLeft: "0.5rem",
    cursor: "pointer",
  },
  actionButtonWithInfoIcons: {
    boxShadow: "none",
    background: "none",
    transition: generateTransition([
      {
        targets: ["box-shadow", "background"],
        speed: "300ms",
      },
      {
        targets: ["opacity"],
        speed: "150ms",
      },
    ]),
    [HOVER_QUERY]: {
      ":hover": {
        boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.08)",
        background: "#fff",
      },
    },
  },
  commentBadgeWithLike: {
    padding: 0,
  },
  shareContainer: {
    ...cardStyles.desktopShareContainer,
    marginRight: "0.4rem",
    marginTop: "-0.25rem",
  },
};

const ratingStyles = {
  star: {
    marginLeft: ".1rem",
    marginRight: ".1rem",
  },
  starEmpty: {
    color: colours.stars.empty,
  },
  starFilled: {
    color: colours.stars.filled,
  },
};

const LIKE_ACTION = [{ type: "heart" }];

const ReviewBubble = (props) => {
  const {
    showLikesBadge,
    showReplyBadge,
    showInfoIcons,
    showBubble,
    showShare,
    userRating: passedUserRating,
    content,
    hoverableStars,
    clickableStars,
  } = props;

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const { styles } = useStyles(baseStyles, props);
  const {
    review,
    entity,
    entity_type,
    isReviewOwner,
    canReply,
    user,
    onToggleInput,
    onDeleteReview,
    confirmComponent,
    showReviewReplyInput,
    isPodcastOwner,
  } = useContext(ReviewCardContext);

  const userRating = passedUserRating || (review && review.get("rating"));

  const isOwner = useMemo(() => isReviewOwner(), [isReviewOwner]);
  const replyActioned = isPodcastOwner && !canReply;

  const replyAction = useMemo(() => {
    let tooltip = showReviewReplyInput ? "Cancel" : "Reply";

    if (replyActioned) {
      tooltip = "Replied";
    }

    return [
      {
        type: "custom",
        tooltip,
        icon: showReviewReplyInput ? faTimes : faReply,
        onClick: replyActioned
          ? () => showModal("review", { review_id: review && review.get("id") })
          : onToggleInput,
        actioned: replyActioned,
      },
    ];
  }, [showReviewReplyInput, onToggleInput, replyActioned, showModal, review]);

  const renderDeleteTooltip = useCallback(() => "Delete Review", []);

  // TODO: Use CardActions or a better way to make these function more consistently
  const renderDeleteBadge = () => (
    <BasicTooltip renderTooltip={renderDeleteTooltip} hideOnTouchDevice>
      {(tooltipProps) => (
        <div
          className={css(
            styles.actionButton,
            showInfoIcons && styles.actionButtonWithInfoIcons
          )}
          {...tooltipProps}
        >
          <FontAwesomeIcon icon={faTrash} onClick={onDeleteReview} />
        </div>
      )}
    </BasicTooltip>
  );

  const renderLikesBadge = () => (
    <div
      className={css(
        styles.actionButton,
        showInfoIcons && styles.actionButtonWithInfoIcons
      )}
    >
      <CardActions actions={LIKE_ACTION} entity={review} entity_type="review" />
    </div>
  );

  const renderReplyBadge = () => (
    <div
      className={css(
        styles.actionButton,
        showInfoIcons && styles.actionButtonWithInfoIcons,
        showLikesBadge && styles.commentBadgeWithLike
      )}
    >
      <CardActions actions={replyAction} entity={review} entity_type="review" />
    </div>
  );

  const renderRatings = () => {
    if (userRating !== undefined) {
      return (
        <RatingWithHover
          readonly
          id={review.get("id") || review.get("entity_id") || "rating"}
          quiet
          rating={userRating}
          numRatings={review.get("rating_count")}
          fontSize={showBubble ? ".65rem" : ".75rem"}
          styles={ratingStyles}
          entity={
            review.has("entity") ? review.get("entity") : entity || review
          }
          entity_type={
            review.has("entity")
              ? review.get("entity_type")
              : entity_type || review
          }
          isAggregate={false}
          author={user}
          authorType="user"
          hoverableStars={hoverableStars}
          clickableStars={clickableStars}
          ariaLabelFunc={({ rating }) =>
            `${getUserDisplayName(user, true)}'s Rating: ${
              Math.round(rating * 100) / 100
            } out of 5`
          }
        />
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Bubble
        {...props}
        showBubble
        header={
          <BubbleHeader
            {...props}
            authorEntity={user}
            authorEntityType="user"
            inlineWithTitle={review ? renderRatings() : null}
          >
            {content && showShare && (
              <div className={css(styles.shareContainer)}>
                <ShareButton
                  entity_type="review"
                  entity={review}
                  buttonTooltip={`Share ${getUserDisplayName(
                    user,
                    true
                  )}'s review`}
                  small
                />
              </div>
            )}
          </BubbleHeader>
        }
        footer={
          <BubbleActions
            {...props}
            show={isOwner || (canReply && showReplyBadge) || showLikesBadge}
          >
            {isOwner && renderDeleteBadge()}
            {showReplyBadge && isPodcastOwner && renderReplyBadge()}
            {showLikesBadge && renderLikesBadge()}
          </BubbleActions>
        }
      />
      {confirmComponent}
      {showReviewReplyInput && <ReviewReplyInput />}
      {canReply && !showReviewReplyInput && <ReviewPrompt />}
      <ReviewReplies
        {...props}
        collapsable
        // we should always show the collapsable version for the podcast owner to,
        // as only the collapsable version has a delete reply button.
        // Otherwise it might not be obvious that they can delete their reply.
        // To everyone else we should atleast show the collapsed reply.
        // So if !showReplies show the collapsed version, if not show the full version
      />
    </Fragment>
  );
};

ReviewBubble.propTypes = {
  showLikesBadge: PropTypes.bool,
  showReplyBadge: PropTypes.bool,
  showInfoIcons: PropTypes.bool,
  showReviewReplyInput: PropTypes.bool,
  showBubble: PropTypes.bool,
  userRating: PropTypes.number,
  showShare: PropTypes.bool,
  content: PropTypes.node,
  hoverableStars: PropTypes.bool,
  clickableStars: PropTypes.bool,
  entity_type: PropTypes.string,
};

ReviewBubble.defaultProps = {
  showLikesBadge: true,
  showReplyBadge: true,
  showInfoIcons: false,
  showReviewReplyInput: false,
  showBubble: false,
  userRating: null,
  showShare: true,
  content: null,
  hoverableStars: false,
  clickableStars: false,
  entity_type: null,
};

export default memo(ReviewBubble);
